@font-face {
  font-family: 'gotham-medium';
  src: url('../fonts/gotham/GothamMedium.ttf');
}

@font-face {
  font-family: 'gotham-bold';
  src: url('../fonts/gotham/GothamBold.ttf');
}

@font-face {
  font-family: 'gotham-light';
  src: url('../fonts/gotham/GothamLight.ttf');
}

::-webkit-input-placeholder, :-ms-input-placeholder, ::placeholder { /* Edge */
  text-align: left;
  font: normal normal 500 12px/16px 'gotham-medium';
  letter-spacing: 0px;
  opacity: 1;
}

.ctgry-img-wrapper {
  background: #00000000 0% 0% no-repeat padding-box;
  border: 1px solid #E3E3E3;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  border-radius: 9px;
  opacity: 1;
  width: 75px;
  height: 75px;
}

.dropdown-menu .disabled {
  opacity: 0.5;
}

.danger-color {
  color: #FF4520 !important;
}

.success-color {
  color: #3BB100 !important;
}

.cstm-chkbox input[type=checkbox] {
  accent-color: #D4A373 !important;
}

.custom-primary-btn {
  background: transparent linear-gradient(180deg, #D4A373 0%, #C4AB95 100%) 0% 0% no-repeat padding-box;
  border-radius: 28px;
  opacity: 1;
}

.cstm-dt input {
  background-color: #F6F2F1;
}

.cstm-chkbox input[type="checkbox"]:checked + label::after {
  content: '';
       position: absolute;
       width: 1.2ex;
       height: 0.4ex;
       background: rgba(0, 0, 0, 0);
       top: 0.9ex;
       left: 0.4ex;
  border: 3px solid red !important;
}

.ctgry-container {
  width: 750px !important;
}

.sl-ctgry {
  border: 2px solid #D4A373;
}

.sl-ctgry-icon {
  position: absolute;
  top: 0;
  right: 0;
}

.px-6-8 {
  padding-left: 6.8rem !important;
  padding-right: 6.8rem !important;
}

.gotham-medium {
  font: normal normal 571 25px/27px 'gotham-medium';
}

.gotham-bold {
  font: normal normal 600 25px/27px 'gotham-medium';
}

.gotham-normal {
  font: normal normal 400 18px/20px 'gotham-light';
}

.w-790 {
  width: 790px;
}

.h-600 {
  height: 600px;
}

.main-content strong {
  font-family: "Gilroy-Bold", sans-serif;
}

.ql-container strong {
  font-weight: bold;
}

.badge {
  color: #FCFCFD !important;
  font: normal normal 600 12px/12px "Gilroy-Regular" !important;
  padding: 10px 10px 7px 10px !important;
}

.badge-secondary {
  background-color: #B1B5C3 !important;
}

.badge-success {
  background-color: #58BD7D !important;
}

.badge-danger {
  background-color: #dc3545;
}

.badge-warning {
  background-color: #F6B233 !important;
}

.sidebar-calc-height {
  height: calc( 100% - 78px );
}

.card-body-text-default-style {
  width: fit-content;
  font: normal normal 600 30px/36px "Gilroy-Regular";
  letter-spacing: 0px;
  color: #32325D;
  margin-bottom: 0px !important;
  padding-top: 12px !important;
  opacity: 1;
}

.card-title-default-style {
  text-align: left;
  font: normal normal 600 13px/15px "Gilroy-Regular";
  letter-spacing: 0px;
  color: #8898AA;
  text-transform: uppercase;
  opacity: 1;
}

.card-heading-txt-style {
  font: normal normal 600 25px/29px "Gilroy-Regular";
  letter-spacing: 0px;
  color: #060606;
  margin-bottom: 5px;
  opacity: 1;
}

.card-heading-secondary-txt {
  font: normal normal 600 14px/24px "Gilroy-Regular";
  letter-spacing: 0px;
  color: #8898AA;
  opacity: 1;
}

.small-text {
  font: normal normal 800 17px/18px "gotham-light" !important;
  color: black !important;
  opacity: 1;
}

.mt-38 {
  margin-top: 38px;
}

.ql-font-serif,
.ql-font-serif * {
  font-family: serif !important;
}

.ql-font-san-serif,
.ql-font-san-serif * {
  font-family: sans-serif !important;
}

.ql-font-monospace,
.ql-font-monospace * {
  font-family: monospace !important;
}

.highlight-today .rdtToday,
.highlight-today .rdtToday:hover {
  background-color: #8898aa !important;
  color: white !important;
}

.rdtDay.rdtActive,
.rdtDay.rdtActive:hover {
  background-color: #7f7fc1 !important;
}

@media (min-width: 1200px) {
  .loginForm .container {
    max-width: 1250px;
  }
}

.loginForm .mt-n18 {
  margin-top: -20rem !important;
}

.loginForm .mt-n13 {
  margin-top: -13rem !important;
}

.authBackground {
  background-image: url('../img/bg.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.bg-info-bh {
  background-image: url('../img/bg.svg');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-default-login {
  background-color: #EDF0F5;
}

.bg-default-login-card {
  background-color: #F6F2F1;
  border-radius: 45px !important;
}

.date-range .dr-icon {
  position: absolute;
  z-index: 99;
  top: 13px;
  left: 10px;
}

.date-range .dr-input {
  padding-left: 38px;
  padding-right: 25px;
}

.date-range .react-datepicker__close-icon::after {
  background-color: #f6b233;
}

.date-range .react-datepicker-popper {
  z-index: 99;
}

.footer-text-style {
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #172B4D;
  opacity: 1;
}

.email-sent-text {
  text-align: center;
  font: normal normal medium 14px/24px "gotham-medium";
  letter-spacing: 0px;
  color: #41404B;
  opacity: 1;
}

.job-detail .card {
  box-shadow: none;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #1916321F;
  margin-bottom: 0px !important;
  border-radius: 15px;
  opacity: 1;
}

.job-detail .profile-img {
  width: 40px !important;
  height: 40px !important;
  border-radius: 8px;
}

.profile .badge {
  background-color: #FFF6E7 !important;
  color: #000000 !important;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  font: normal normal 600 18px/46px "Gilroy-Regular" !important;
  opacity: 1;
}

.secondary-label {
  text-align: left;
  font: normal normal 600 14px/23px 'gotham-light';
  letter-spacing: 0px;
  color: #141414;
  opacity: 1;
}

.custom-input-style {
  text-align: left;
  font: normal normal 600 14px/17px "Gilroy-Regular";
  letter-spacing: 0px;
  color: #000000 !important;
  opacity: 1;
}

.pt-34 {
  padding-top: 34px !important;
}

.primary-label {
  text-align: left;
  font: normal normal 600 17px/20px "gotham-medium";
  letter-spacing: 0px;
  color: #14142B;
  opacity: 1;
}

.secondary-placeholder, .secondary-placeholder::-webkit-input-placeholder, .secondary-placeholder::-moz-placeholder {
  text-align: left;
  font: normal normal 600 14px/17px "gotham-medium";
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.back-btn {
  text-align: left;
  font: normal normal 600 17px/23px "Open Sans";
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.gallery-img {
  width: 85px;
  height: 85px;
  border-radius: 20px;
  opacity: 1;
}

.cross-icon {
  position: absolute;
  bottom: -36px;
  right: -5px;
  z-index: 999;
  cursor: pointer;
}

.x-icon {
  padding-right: 15px;
  padding-left: 7px;
  opacity: 1;
}

.profile .main-txt {
  text-align: center;
  font: normal normal 600 14px/17px "Gilroy-Regular";
  letter-spacing: 0px;
  color: #191632;
  opacity: 1;
}

.profile .secondary-txt {
  text-align: left;
  font: normal normal medium 13px/16px "Gilroy-Regular";
  letter-spacing: 0px;
  color: #040415;
  bottom: 0;
  opacity: 0.6;
}

.cursor-pointer{
  cursor:  pointer !important;
}

.profile .rating {
  font: normal normal medium 13px/16px "Gilroy-Regular";
  letter-spacing: 0px;
  color: #040415;
  opacity: 0.6;
}

.job-detail .main-content {
  width: 100%;
  border-radius: 12px;
  opacity: 1;
}

.w-60 {
  width: 60% !important;
}

.job-detail .img-style {
  height: 150px;
}

.job-detail .small-img-style {
  height: 36px;
}

.job-detail .small-content {
  width: 43px;
  border-radius: 4px;
  opacity: 0.7;
}

.job-detail .dot {
  font-size: 0.50em;
  margin: 6px;
  vertical-align: text-bottom;
}

.job-detail .main-text {
  font: normal normal 600 16px/38px "Gilroy-Regular";
  letter-spacing: 0px;
  color: #23262F;
  opacity: 1;
}

.job-detail .bdgt-txt {
  font: normal normal 600 12px/20px "Gilroy-Regular";
  color: #F6B233;
  text-transform: capitalize;
  vertical-align: text-bottom;
  opacity: 1;
}

.job-detail .bg {
  background-color: #F8F8F8 !important;
  text-transform: none !important;
  border-radius: 52px;
  opacity: 1;
}

.job-detail .profile-header-txt {
  font: normal normal 600 11px/12px "Gilroy-Regular";
  letter-spacing: 1.04px;
  color: #8898AA;
  text-transform: uppercase;
  opacity: 1;
}

.job-detail .main-txt {
  color: #000000 !important;
}

.job-detail .heading-txt
{
  text-align: left;
  font: normal normal 600 17px/20px "Gilroy-Regular";
  letter-spacing: 0px;
  color: #14142B;
  opacity: 1;
}

.job-detail .ts .badge {
  width: 103px;
  height: 30px;
  padding-top: 10px !important;
  background-color: #F6B233 !important;
  color: #000000 !important;
  border-radius: 4px;
  opacity: 1;
}

.job-detail .secondary-txt {
  color: #8E8E93 !important;
}

.job-detail .sl-img {
  border: 2px solid #f6b233;
}

.auth-logo-text {
  font: normal normal bold 40px/49px "Gilroy-Regular";
  color: #F6B233;
  opacity: 1;
  letter-spacing: 0px;
}

.video-close-icon {
  height: 20px;
  position: absolute;
  right: -20px;
  top: -5px;
}

.video-icon {
  left: 95px;
  z-index: 99;
  top: 50px;
}

.logo-color {
  color: #F6B233;
}

.sidebar-logo-style {
  font: normal normal bold 30px/36px "Gilroy-Regular";
  letter-spacing: 0px;
  opacity: 1;
}

.fill-default-login {
  fill: #EDF0F5;
}

.default-color,
.icon-color {
  text-align: center;
  font: normal normal 600 28px/36px 'gotham-medium';
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.main-font-color {
  color: #F6B233;
}

.active.nav-item .nav-link-text {
  color: #000000;
}

.nav-link-text {
  text-align: left;
  font: normal normal 500 15px/15px 'gilroy-medium';
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.icon-color-light {
  color: #F6B233;
}

.black-color {
  color: #172B4D;
}

.default-button-background,
.default-button-background:hover,
.btn-info:not(:disabled):not(.disabled):active {
  background: transparent linear-gradient(180deg, #D4A373 0%, #C4AB95 100%) 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border-color: #D4A373;
}

.default-secondary-btn {
  border: 1px solid #D4A373 ;
  border-radius: 28px;
  text-align: center;
  font: normal normal 600 13px/16px "gotham-medium";
  letter-spacing: 0px;
  color: #D4A373;
  opacity: 1;
}

.modal-primary-btn {
  text-align: center;
  font: normal normal 600 14px/18px "gotham-medium";
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  border: 0;
  background: transparent linear-gradient(180deg, #D4A373 0%, #C4AB95 100%) 0% 0% no-repeat padding-box;
  border-radius: 28px;
}

.img-preview-div {
    padding: 15px;
    border-radius: 0px 0px 7px 7px;
    border: 0px 1px 1px 1px solid;
    border-bottom: 1px solid lightgrey;
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
}

.img-preview-div > img {
  border-radius: 10px;
}

.default-button-style {
  font: normal normal 600 20px/22px 'gotham-medium';
  letter-spacing: 0px;
  color: #FFFFFF;
}

.default-header-btn-style {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  letter-spacing: 0px;
  cursor: pointer !important;
  text-align: center;
  font: normal normal 500 16px/16px 'gotham-medium';
  letter-spacing: 0px;
  color: #D4A373;
  text-transform: capitalize;
  opacity: 1;
}

.default-header-btn-style:hover {
  box-shadow: 0px 4px 6px #32325D1C;
}

.defauly-hr-line {
  margin: 1px;
  color: #FFFFFF36;
}

.navbar-expand-end {
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.navbar-brand-img {
  max-height: 2.5rem !important;
}

.navbar-expand-space {
  justify-content: space-between !important;
}

.meditationImage {
  height: 3rem;
  width: 4rem;
  object-fit: cover;
  border-radius: 5%;
}

.meditationImageTable {
  height: 2.5rem;
  width: 2.5rem;
  object-fit: cover;
  border-radius: 10%;
}

.coverImage {
  height: 35px;
  width: 22px;
  object-fit: cover;
  border-radius: 5%;
}

.meditationImageDetail {
  height: 3.9rem;
  width: 4rem;
  object-fit: cover;
}

.action-bg {
  background-color: #ffffff !important;
  border-color: #E6E8EC;
}

.custom-input {
  display: block;
  cursor: pointer;
  padding: 12px;
  border: 1px solid;
  border-color: lightgrey;
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.custom-input .choose-file {
  background-color: #8898AA;
  position: absolute;
  left: 2px;
  top: 2px;
  color: #000000;
  font: normal normal 400 10px/11px "Gilroy-Regular";
}

.modal-body{
  text-align: center;
  font: normal normal 400 14px/16px "gotham-light";
  letter-spacing: -0.11px;
  color: #000000;
  opacity: 1;
}

.modal-title {
  margin-top: 7px !important;
  font: normal normal 500 22px/22px "gotham-medium";
  letter-spacing: 0px;
  color: #334856;
  opacity: 1;
}

.custom-input .file-name {
  position: absolute;
  left: 80px;
  top: 5px;
  font: normal normal 600 12px/14px "Gilroy-Regular";
  letter-spacing: 0px;
  color: #525F7F;
}

.custom-input .browse {
  position: absolute;
  right: 6px;
  top: 0px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-left: 1px solid #DEE2E6;
  font: normal normal 600 12px/14px "Gilroy-Regular";
  letter-spacing: 0px;
  color: #525F7F;
}



.mw-210 {
  min-width: 210px !important;
}

.mt-25 {
  margin-top: 25px;
}

.action-txt {
  text-align: left;
  font: normal normal 500 14px/16px 'gotham-medium';
  letter-spacing: 0px;
  color: #353945;
  opacity: 1;
}

.action-dot {
  vertical-align: inherit;
  color: #D4A373 !important;
}

.rhap_additional-controls,
.rhap_volume-controls,
#rhap_current-time {
  display: none !important;
}

.rhap_container {
  background: #f9f9f9 !important;
  border: 1px solid #dee0e4 !important;
  border-radius: 5%;
  box-shadow: none !important;
}

.rhap_main {
  flex-direction: row-reverse !important;
}

.rhap_controls-section {
  margin-top: 0 !important;
  flex: none !important;
}

.rhap_progress-indicator {
  background: white !important;
  border: 1px solid black !important;
  height: 15px !important;
  width: 15px !important;
  top: -5px !important;
  margin-left: -7px !important;
}

.rhap_progress-filled {
  background-color: #3a3a73 !important;
}

.playIcon {
  color: #3a3a73;
  font-size: small;
}

.rhap_main-controls button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content !important;
}

.rhap_total-time {
  font-size: small !important;
}

.headingColor {
  color: #3a3a73;
}

.headingColor-secondary {
  color: darkblue;
}

.active.nav-item {
  background: #F6F2F1 0% 0% no-repeat padding-box;
  opacity: 1;
}

.dataTable {
  width: 100%;
  table-layout: fixed;
}

.dataTable td {
  padding: 0.5rem 1rem;
}

.form-control:focus{
  border-color: #E3E3E3;
}

.is-invalid .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused),
.is-invalid .ck.ck-toolbar,
.dropzone.invalid-dropzone .dz-message,
.is-invalid .ql-editor.ql-blank,
.rdt.is-invalid input {
  border-color: #fb6340 !important;
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused),
.ck.ck-toolbar {
  border-color: #dee2e6 !important;
}



.dashedBorder {
  border: 1px dashed #dee2e6;
}

.addBtn,
.addBtn:hover {
  color: white;
  background-color: #7f7fc1;
  border-color: #7f7fc1;
}

.dropzone .dz-button {
  color: black;
  background: white;
}

.dz-message {
  padding: 1rem;
}

.searchBox {
  width: 250px;
}

.searchBox::placeholder, .dr-input::placeholder, .select2-selection__rendered {
  font: normal normal 600 14px/16px "Gilroy-Regular" !important;
  letter-spacing: 0px !important;
  color: #525F7F !important;
  opacity: 1 !important;
}

.select2-dropdown {
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 8px 16px #00000024 !important;
  border-radius: 9px !important;
  margin-top: 2px !important;
  opacity: 1;
}

.setting-dropdown {
  top: -50px !important;
  left: -12px !important;
}

.select2-selection__rendered {
  font: normal normal normal 14px/19px "Open Sans";
  letter-spacing: 0px;
  color: #525F7F;
  opacity: 1;
  padding-top: 6px !important;
}

.select2-results__option {
  font: normal normal normal 14px/19px "Open Sans";
  letter-spacing: 0px;
  color: #525F7F;
  text-transform: capitalize;
  opacity: 1;
}

.select2-results__option--highlighted {
  color: #367CEA !important;
}

.ql-snow .ql-tooltip::before,
.ql-action::after {
  content: "" !important;
}

.dz-preview {
  display: none;
}

.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 10000;
}

.spinner-container-admin {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8ad;
  z-index: 10000;
}

.spinner {
  left: 55%;
  top: 50%;
  z-index: 1000;
  position: absolute;
  background-color: #7f7fc1;
  padding: 5px;
  border-radius: 50%;
}

.spinner-admin {
  left: 49%;
  top: 43%;
  z-index: 1000;
  position: absolute;
}

.successToast {
  background: #FFFFFF !important;
  border-color: #FFFFFF !important;
}

.updateToast {
  background: #FFFFFF !important;
  border-color: #FFFFFF !important;
}

.errorToast {
  background: #FFFFFF !important;
  border-color: #FFFFFF !important;
}

.successToast div,
.updateToast div,
.errorToast div {
  color: #000000;
}

.w-5 {
  width: 5% !important;
}

.w-15 {
  width: 15% !important;
}

.w-10 {
  width: 10% !important;
}

.w-7 {
  width: 7% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-20 {
  width: 20% !important;
}

.w-45 {
  width: 45% !important;
}

.w-40 {
  width: 40% !important;
}

.w-65 {
  width: 65% !important;
}

.overflowStyle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mirror {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

p {
  color: black;
}

.moodModal select,
.pageEntry select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
}

.moodModal select + i.fa {
  float: right;
  margin-top: -30px;
  margin-right: 5px;
  pointer-events: none;
  background-color: #fff;
  padding-right: 5px;
}

.clear {
  position: absolute;
  top: 34px;
  right: 34px;
}

.new-line {
  white-space: pre;
}

.rdtPicker .rdtTimeToggle,
.rdtPicker .rdtTimeToggle:hover {
  visibility: visible !important;
  background: none !important;
}

.rdtTimeToggle::before {
  visibility: hidden !important;
}

.datePicker-invalid input {
  border-color: #fb6340;
}

.url.form-control {
  color: #F6B233 !important;
  text-decoration: underline;
}

.search .rdtPicker {
  left: -88px !important;
}

table.dataTable {
  border-bottom: none !important;
  margin-bottom: 0px !important;
}

.analytic-header-btn-style {
  text-align: center;
  font: normal normal normal 14px/19px "Open Sans";
  letter-spacing: 0px;
  color: #172B4D;
  border-radius: 6px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 6px #32325D1C;
  border: none;
  opacity: 1;
  cursor: pointer;
}

.analytic-header-btn-active {
  background: #F6B233 0% 0% no-repeat padding-box !important;
}

.btn {
  padding: 1rem 1.25rem !important;
}

.btn-icon-only.btn-sm, .btn-group-sm > .btn-icon-only.btn {
  width: 30px !important;
  height: 30px !important;
}

.analytic-card .analytic-header-btn-style:hover {
  box-shadow: 0px 6px 8px #32325D1C;
}

.search-field {
  display: flex;
}

.search-field .filter {
  min-width: 200px;
}


.search-field .filter .filter-icon {
  position: absolute;
  right: 10px;
  bottom: 15px;
}

.search-field .search-icon {
  border-right: 0px;
  padding-right: 0px;
  border-radius: 3px 0px 0px 3px;
}

.search-field .icon-focus {
  border-color: #F6B233;
}

.search-field .search-group {
  min-width: 460px !important;
}

.back-btn {
  text-align: left;
  font: normal normal 600 17px/23px "Open Sans";
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.select2-container .select2-selection--single:focus {
  border-color: #F6F2F1 !important;
}

.time-icon {
  right: 15px;
  top: 15px;
}

.default-table-txt {
  text-align: left;
  font: normal normal 500 14px/24px "Gilroy-Regular";
  letter-spacing: 0px;
  color: #23262F;
  opacity: 1;
  }

.search-field .searchBox {
  border-left: 0px;
  padding-left: 10px;
}

.page-item.active .page-link {
  background-color: #D4A373;
  border-color: #D4A373;
}

.rdtCounters .rdtCounter:last-child .rdtCount {
  font-size: x-small !important;
}

.close {
  opacity: 1 !important;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 1 !important;
}

.pageEntry select {
  background-color: transparent;
}

.pageEntry select + i.fa {
  margin-left: -12px;
}

.text-xxs {
  font-size: xx-small;
}

.form-control[readonly] {
  background-color: transparent !important;
}

.border-top-style {
  border-top: 1px solid #8898AA26;
}

.navbar-vertical.navbar-expand-xs .navbar-nav > .nav-item > .nav-link.active {
  background-color: #f8f8fe;
  margin-right: 0;
  margin-left: 0;
  padding-left: 1.5rem;
}

.diable-label {
  cursor: not-allowed;
  background-color: #e9ecef;
}

.input-default-style {
  font: normal normal 500 14px/16px "Gilroy-Regular";
  color: #000000 !important;
}

.input-disable {
  opacity: 0.5 !important;
}

.input-primary-style {
  font: normal normal 500 16px/19px "gotham-medium";
  color: #000000;
}

.default-text-style {
  text-align: center;
  font: normal normal 600 14px/19px "Open Sans";
  letter-spacing: 0px;
  color: #F6B233;
  opacity: 1;
}

.disable-txt-btn {
  cursor:not-allowed;
}

.input-secondary-style {
  font: normal normal 500 14px/23px 'gotham-medium';
  letter-spacing: 0px;
  color: #141414 !important;
}

.thead-bh {
  background: #F6F2F1 0% 0% no-repeat padding-box;
  opacity: 1;
}

.thead-bh th {
  font-size: 0.8rem !important;
}

.dataTable thead th {
  text-align: left;
  font: normal normal 500 12px 'gotham-medium';
  letter-spacing: 1.33px;
  color: #CAB19B;
  opacity: 1;
}

.searchColor {
  color: #8898aa !important;
}

.auth-form .form-control {
  height: calc(1.5em + 1.25rem + 16px);
  padding-top: 13px !important;
}

.input-group .input-icon{
  border-radius: 14px 0px 0px 14px !important;
  border: 1px solid #4A4A4A !important;
  opacity: 1 !important;
}

.input-group .input-primary-style{
  border-radius: 0px 14px 14px 0px !important;
  border-top: 1px solid #4A4A4A !important;
  border-bottom: 1px solid #4A4A4A !important;
  border-right: 1px solid #4A4A4A !important;
  opacity: 1 !important;
}

.bolder {
  font: normal normal 600 17px/23px "Open Sans";
}

.tableURL {
  text-decoration: none;
  color: black;
}

.tbl-img {
  width: 38px !important;
  height: 40px;
  border-radius: 8px;
}

.tbl-primary-txt {
  font: normal normal 600 14px/17px "Gilroy-Regular";
  letter-spacing: 0px;
  color: #191632;
  opacity: 1;
}

.tbl-txt {
  text-align: left;
  font: normal normal 600 11px 'gotham-light';
  letter-spacing: 0px;
  color: #23262F;
  opacity: 1;
}

.dflt-badge {
  background: #F6F2F1 0% 0% no-repeat padding-box;
  padding: 5px 5px 3px 5px;
  border-radius: 3px;
}

.mr-t-4 {
  margin-top: -4rem;
}

.pb-40 {
  padding-bottom: 40px;
}

.txt-capitalize::first-letter {
  text-transform: capitalize;
}

.tbl-main-txt {
  font: normal normal 600 14px/24px "gotham-medium";
  letter-spacing: 0px;
  color: #F6B233;
  opacity: 1;
}

.imageViewerClose {
  position: absolute;
  right: 25px;
  top: 10px;
}

.actionDropdown .dropdown-menu .dropdown-item {
  font-size: 0.78rem;
}

.dropdown-item-style {
  font: normal normal 600 12px/14px "Gilroy-Regular";
  letter-spacing: 0px;
  color: #172B4D;
  opacity: 1;
}

.actionDropdown .dropdown-menu {
  min-width: 10rem;
}

.disabled-action {
  opacity: 0.5;
  pointer-events: none;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border: 5px solid #D4A373;
  background-color: #ffffff;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border: 5px solid #D4A373;
  background-color: #ffffff;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: #ffffff;
  border: 5px solid #D4A373;
}

.custom-control-label::before {
  width: 1.2rem;
  height: 1.2rem;
  left: -2rem;
  background-color: #ffffff;
}

.custom-radio-style {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 3px 2px rgb(233 236 239 / 5%);
  padding: 0.625rem 3rem;
}

.custom-radio-style .label-txt {
  text-align: left;
  font: normal normal 500 10px/18px 'gotham-medium';
  letter-spacing: 0.6px;
  opacity: 1;
  padding-top: 5px;
}

.custom-radio-checked {
  border: 2px solid #D4A373;
}
